const { request } = require('./request');
const baseURL = "/api/users";

export const getUserInfo = () => {
    return request(`${baseURL}/me`);
};

export const requestPasswordReset = (email) => {
    return request(`${baseURL}/reset-request`, {
        method: 'POST',
        json: {
          email
        }
    });
}

export const resetPassword = (token, password) => {
    return request(`${baseURL}/reset-password`, {
        method: 'POST',
        json: {
          password
        },
        token,
    });
}

export const updateProfile = (data) => {
  // { name: 'name', password: 'password' }
    return request(`${baseURL}/me`, {
        method: 'PUT',
        json: data,
    });
}

export const deleteAccount = () => {
    return request(`${baseURL}/me`, {
        method: 'DELETE',
    });
}

export const getUsage = () => {
    return request(`${baseURL}/me/usage`, {
        method: 'GET',
    });
}

export const getSubscription = () => {
    return request(`${baseURL}/me/subscription`, {
        method: 'GET',
    });
}

export const cancelSubscription = () => {
    return request(`${baseURL}/me/subscription/cancel`, {
        method: 'DELETE',
    });
}

export const getCheckoutSession = (sessionId) => {
    return request(`/api/checkout?session_id=${sessionId}`, {
        method: 'GET',
    });
}

export const getBillingPortal = () => {
    return request(`/api/billing/portal`, {
        method: 'POST',
    });
}

export const getUsers = (page, limit, query) => {
    return request(`/api/users`, {
        method: 'GET',
    });
}

export const impersonate = (userId) => {
    return request(`/api/users/${userId}/impersonate`, {
        method: 'POST',
    });
}