import React, { useState, useEffect } from 'react';
import { Card, Title, Button, Flex, Text, Divider } from '@tremor/react';
import SourceTable from './table';
import SourceForm from './form';
import { Dialog, DialogPanel } from "@tremor/react";
import Nav from '../../components/nav';

import * as sourcesAPI from '../../services/sources';
import {   PlusCircleIcon, GlobeAltIcon
} from '@heroicons/react/24/outline';

export default function Sources() {
  const [sources, setSources] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [newSource, setNewSource] = useState('');

  const refresh = async () => {
    try {
      await getSources();
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async (id) => {
    console.log(id);
    try {
      await sourcesAPI.remove(id);
      await getSources();
    } catch (error) {
      console.error(error);
    }
  }

  const getSources = async () => {
    try {
      const response = await sourcesAPI.list(page, limit);
      setSources(response.data);
      setTotal(response.total);
    } catch (error) {
      console.error(error);
    }
  }

  // Read operation
  useEffect(() => {
    getSources();
  }, []);

  // Add functions for Create, Update, and Delete operations here

  return (
    <div>
    <Nav />
    <main className="p-4 md:p-10 mx-auto max-w-7xl">
      <Flex>
        <div>
          <Title>Sources</Title>
          <Text>
            Sources are endpoints to send data to Zafron via HTTP. 
          </Text>
        </div>
        <div>
          <Button variant="primary" size='xs' onClick={() => setIsOpen(true)}>New Source</Button>
        </div>
      </Flex>
      <Divider />
      <Card className="mt-6">
      {sources.length > 0  ? ( <SourceTable sources={sources} onDelete={onDelete} /> ) : (
          <NoData open={() => setIsOpen(true)} />
        )}
      </Card>
  </main>
    <Dialog open={isOpen} onClose={(val) => setIsOpen(val)} static={true}>
      <DialogPanel>
        <SourceForm onCancel={refresh} />
      </DialogPanel>
    </Dialog>
  </div>
  );
};

function NoData({ open }) {
  return (
    <>
        <div className="mt-4 flex h-52 items-center justify-center rounded-tremor-small border border-dashed border-tremor-border p-4 dark:border-dark-tremor-border">
          <div className="text-center">
            <GlobeAltIcon
              className="mx-auto h-7 w-7 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
              aria-hidden={true}
            />
            <p className="mt-2 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Empty
            </p>
            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
              Create a new source to receive data from other platforms such as a LoRaWAN Network Server or generic HTTP endpoint.
            </p>
            <p>
            </p>
            <Button
              icon={PlusCircleIcon}
              onClick={open}
              type="button"
              className="mt-6 inline-flex items-center gap-1.5 whitespace-nowrap rounded-tremor-small bg-tremor-brand px-3 py-2 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis"
            >
              New Source
            </Button>
          </div>
        </div>
    </>
  );
}